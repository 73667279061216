import { isFirefox } from 'util/Device'
// REACT IMPORTS
import { lazy, Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// ABSOLUTE IMPORTS
import 'styles/main.css'
import MainLayoutSkeletonLoader from 'components/browse/skeletonLoaders/MainLayout'
import CanonicalLink from 'components/common/CanonicalLink'
import useEvents from 'components/hooks/analytics/useEvents'
import { useChromecast } from 'components/hooks/useChromecast'
import { useRafState } from 'components/hooks/useRafState'
import { LibrarySplashScreen } from 'components/LibrarySplashScreen'
import { AuthLayout } from 'components/registration/ui'
import { TermsOfService } from 'components/terms/TermsOfService'
import { Loading } from 'components/ui'
import ErrorBoundary from 'ErrorBoundary'
import { useKinds } from 'state/useKinds'
import { useLanguages } from 'state/useLanguages'
import { useLogin } from 'state/useLogin'
import useLaunchDarklyObserver from 'state/useLaunchDarklyObserver'

const Player = lazy(() =>
  import('pages/play/[...id]').then((module) => ({ default: module.Player })),
)
const TestReflowablePlayer = lazy(() =>
  import('pages/test/media/reflowable/[titleId]'),
)
const TestFixedLayoutPlayer = lazy(() => import('pages/test/media/fixedlayout'))
const TestComicPlayer = lazy(() => import('pages/test/media/comic/[titleId]'))
const Signup = lazy(() => import('pages/signup'))
const _RendezvousAuthorization = lazy(() => import('pages/link'))
const Home = lazy(() =>
  import('pages').then((module) => ({ default: module.Home })),
)
const MainLayout = lazy(() =>
  import('./components/Main').then((module) => ({ default: module.Main })),
)
const Login = lazy(() => import('pages/login'))
const Register = lazy(() => import('pages/register'))
const CantFindLibrary = lazy(() => import('pages/find-library'))
const ResetPassword = lazy(() => import('pages/password/reset-password'))

export const App = () => {
  const [isInitializing, setReady] = useRafState(true)
  const { chromecastConnectionCheck } = useChromecast()
  const { sendAppStartEvent } = useEvents()
  const [appInit, setAppInit] = useState(false)
  useLaunchDarklyObserver()

  // preload kinds in Apollo cache
  const fetchKinds = useKinds((state) => state.fetch)
  const getLanguages = useLanguages((state) => state.getLanguages)

  // load patron / library from stored token (if available)
  const { initializePatron } = useLogin()

  useEffect(() => {
    // this effect should only run one time on load of the app
    async function setupState() {
      await Promise.all([fetchKinds(), getLanguages(), initializePatron()])
      setReady(false)
    }
    chromecastConnectionCheck()
    setupState()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isInitializing && !appInit) {
      sendAppStartEvent({
        referralSource: document.referrer,
      })

      setAppInit(true)
    }
  }, [appInit, isInitializing, sendAppStartEvent])

  const onClick = (e) => {
    // remove global style for focus if the event was a mouse event not some weird pointer event
    if (e?.detail > 0 && document.body.classList.contains('show-focus')) {
      document.body.classList.remove('show-focus')
    }
  }

  const onKeyUp = (e) => {
    // add global style for focus
    // isFirefox() handles when a user is using accessibility to tab to and focus
    // see for more details: https://midwesttapes.atlassian.net/wiki/spaces/WWW/pages/1281327188/01+09+2023+-+Firefox+Accessibility
    if (
      (e.shiftKey && e.key === 'Tab') ||
      e.key === 'Tab' ||
      (isFirefox() && e.key === 'Enter')
    ) {
      if (!document.body.classList.contains('show-focus')) {
        document.body.classList.add('show-focus')
      }
    }
  }

  useEffect(() => {
    // event listener for tab/shift+tab key up
    window.addEventListener('click', onClick)
    window.addEventListener('keyup', onKeyUp)
    // event listener for clicks

    return () => {
      //kill event listeners
      window.removeEventListener('click', onClick)
      window.removeEventListener('keyup', onKeyUp)
    }
  })

  // while initially loading kinds and patron info, we
  // can't allow the rest of the app to load
  if (isInitializing) return <Loading />

  return (
    <div className="h-full">
      <ErrorBoundary>
        <LibrarySplashScreen>
          <TermsOfService>
            <CanonicalLink />
            <Routes>
              <Route
                path="/play/:titleId"
                element={
                  <Suspense fallback={<Loading />}>
                    <Player />
                  </Suspense>
                }
              />
              <Route
                path="/play/:titleId/:episodeIndex"
                element={
                  <Suspense fallback={<Loading />}>
                    <Player />
                  </Suspense>
                }
              />
              <Route
                path="/test/media/reflowable/:titleId"
                element={
                  <Suspense fallback={<Loading />}>
                    <TestReflowablePlayer />
                  </Suspense>
                }
              />
              <Route
                path="/test/media/fixedlayout/:titleId"
                element={
                  <Suspense fallback={<Loading />}>
                    <TestFixedLayoutPlayer />
                  </Suspense>
                }
              />
              <Route
                path="/test/media/comic/:titleId"
                element={
                  <Suspense fallback={<Loading />}>
                    <TestComicPlayer />
                  </Suspense>
                }
              />
              <Route
                path="/signup:signupNumber"
                element={
                  <Suspense fallback={<Loading />}>
                    <Signup />
                  </Suspense>
                }
              />
              <Route
                path="/link"
                element={
                  <Navigate to="/my/settings/link-a-streaming-device" replace />
                }
              />
              <Route
                path="login"
                element={
                  <Suspense
                    fallback={
                      <AuthLayout>
                        <Loading />
                      </AuthLayout>
                    }>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="login/*"
                element={
                  <Suspense
                    fallback={
                      <AuthLayout>
                        <Loading />
                      </AuthLayout>
                    }>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="signup/*"
                element={<Navigate to="/register" replace={true} />}
              />
              <Route
                path="registration/*"
                element={<Navigate to="/register" replace={true} />}
              />
              <Route
                path="register"
                element={
                  <Suspense
                    fallback={
                      <AuthLayout>
                        <Loading />
                      </AuthLayout>
                    }>
                    <Register />
                  </Suspense>
                }
              />
              <Route
                path="register/:libraryUrlRegistrationId"
                element={
                  <Suspense
                    fallback={
                      <AuthLayout>
                        <Loading />
                      </AuthLayout>
                    }>
                    <Register />
                  </Suspense>
                }
              />
              <Route
                path="find-library"
                element={
                  <Suspense
                    fallback={
                      <AuthLayout>
                        <Loading />
                      </AuthLayout>
                    }>
                    <CantFindLibrary />
                  </Suspense>
                }
              />
              <Route
                path="reset-password"
                element={
                  <Suspense fallback={<Loading />}>
                    <ResetPassword />
                  </Suspense>
                }
              />

              <Route path="/">
                <Route
                  index
                  element={
                    <Suspense fallback={<Loading />}>
                      <Home />
                    </Suspense>
                  }
                />
                <Route
                  path="/*"
                  element={
                    <Suspense fallback={<MainLayoutSkeletonLoader />}>
                      <MainLayout />
                    </Suspense>
                  }
                />
              </Route>
            </Routes>
          </TermsOfService>
        </LibrarySplashScreen>
      </ErrorBoundary>
    </div>
  )
}

export default App
