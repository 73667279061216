// REACT IMPORTS
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

// ABSOLUTE IMPORTS
import { api as libraryApi } from 'api/librariesApi'
import Checkbox from 'components/forms/Checkbox'
import TextField from 'components/forms/TextField'
import useEvents from 'components/hooks/analytics/useEvents'
import { Stepper } from 'components/registration/ui'
import { Loading } from 'components/ui'
import { useRegistrationState } from 'state/useRegistration'
import { PrimaryButton } from 'ui/buttons/PrimaryButton'

const messages: IntlMessage = defineMessages({
  libraryCard: {
    id: 'registration.connect.libraryCard',
    defaultMessage: 'Library Card',
  },
  libraryCardRequired: {
    id: 'registration.connect.libraryCard.required',
    defaultMessage: 'Library Card is required',
  },
  libraryPin: {
    id: 'registration.connect.libraryPin',
    defaultMessage: 'Library PIN',
  },
  libraryPinRequired: {
    id: 'registration.connect.libraryPin.required',
    defaultMessage: 'Library PIN is required',
  },
  libraryValidationError: {
    id: 'registration.connect.libraryValidation',
    defaultMessage:
      'There was an issue validating your library, please try again.',
  },
  locationValidationError: {
    id: 'registration.connect.locationValidation',
    defaultMessage:
      'Your location is required to register without a library card, please check your location settings and try again.',
  },
  firstName: {
    id: 'registration.connect.firstName',
    defaultMessage: 'First Name',
  },
  firstNameRequired: {
    id: 'registration.connect.firstName.required',
    defaultMessage: 'First Name is required',
  },
  lastName: {
    id: 'registration.connect.lastName',
    defaultMessage: 'Last Name',
  },
  lastNameRequired: {
    id: 'registration.connect.lastName.required',
    defaultMessage: 'Last Name is required',
  },
})

type CountryCode = 'AU' | 'NZ' | 'CA'

type Position = {
  coords: GeolocationCoordinates
  timestamp: number
}

const getCountryNameForLibrary = (country: CountryCode) => {
  switch (country) {
    case 'AU':
      return (
        <FormattedMessage
          id="registration.country.au"
          defaultMessage="Australia"
        />
      )
    case 'NZ':
      return (
        <FormattedMessage
          id="registration.country.nz"
          defaultMessage="New Zealand"
        />
      )
    case 'CA':
    default:
      return (
        <FormattedMessage
          id="registration.country.ca"
          defaultMessage="Canada"
        />
      )
  }
}

export default function ConnectLibrary({
  onBack = () => {},
  onNext = () => {},
}: BaseRegistrationProps) {
  // HOOKS
  const { sendRegistrationEvent, setPageLoaded } = useEvents()
  const { formatMessage } = useIntl()
  const formData = useRegistrationState((state) => state.formData)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError: setFormError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { ...formData },
  })

  // STATE
  let coords: GeolocationCoordinates
  const { library } = formData
  const [error, setError] = useState<string>('')
  const isProvisionalChecked = watch('provisional')

  setPageLoaded(
    { name: 'REGISTRATION_LIBRARY_INFO' },
    {
      category: 'REGISTRATION',
      label: 'connect_with_your_library',
    },
  )

  useEffect(() => {
    // kinda lame but if the user changes some values and then updates the provisional checkbox
    // we don't want to send along unnecessary data
    setValue('libraryCard', '')
    setValue('libraryPin', '')
    setValue('firstName', '')
    setValue('lastName', '')
  }, [isProvisionalChecked, setValue])

  function getPosition(): Promise<Position> {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject),
    )
  }

  async function onSubmit({
    libraryCard,
    libraryPin,
    nonUsAgreement,
    provisional,
    firstName,
    lastName,
  }: RegistrationFormData) {
    const libraryId = library?.id || -1

    if (isProvisionalChecked) {
      try {
        const position = await getPosition()
        coords = position.coords
      } catch (err) {
        return setError(formatMessage(messages.locationValidationError))
      }
    }

    try {
      await libraryApi.validateLibrary({
        libraryId,
        libraryCard,
        libraryPin,
        latitude: coords?.latitude,
        longitude: coords?.longitude,
      })

      sendRegistrationEvent({
        label: 'submit_card',
        value: provisional ? { provisional: true } : undefined,
      })

      setError('')
      onNext({
        coords,
        libraryCard,
        libraryPin,
        nonUsAgreement,
        provisional,
        firstName,
        lastName,
      })
    } catch (err: any) {
      const reason = err?.response?.data?.reason
      if (reason === 'CARD_EXISTS_ERROR' || reason === 'CARD_PIN_ERROR') {
        if (reason === 'CARD_EXISTS_ERROR' || !library?.pinRequired) {
          return setFormError('libraryCard', {
            message: err?.response?.data?.message,
          })
        }
      }
      const errorMessage =
        err?.response?.data?.message ??
        formatMessage(messages.libraryValidationError)

      sendRegistrationEvent({ label: 'card_authentication_error' })

      return setError(errorMessage)
    }
  }

  return (
    <div className="flex h-full flex-col">
      <Stepper
        onBack={() =>
          onBack({
            libraryCard: undefined,
            libraryPin: undefined,
            nonUsAgreement: undefined,
            provisional: undefined,
            firstName: undefined,
            lastName: undefined,
          })
        }
      />
      <form className="flex h-full flex-col" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="mb-2 text-center text-3xl font-bold">
          <FormattedMessage
            id="registration.library.connect"
            defaultMessage="Connect With Your Library"
          />
        </h1>

        {library?.registrationMessage && <p>{library?.registrationMessage}</p>}

        <div className="mt-4">
          {isProvisionalChecked && (
            <>
              <TextField
                disabled={isSubmitting}
                id="firstName"
                error={errors?.firstName?.message}
                label={formatMessage(messages.firstName)}
                placeHolder={formatMessage(messages.firstName)}
                {...register('firstName', {
                  required: formatMessage(messages.firstNameRequired),
                })}
              />

              <TextField
                disabled={isSubmitting}
                id="lastName"
                error={errors?.lastName?.message}
                label={formatMessage(messages.lastName)}
                placeHolder={formatMessage(messages.lastName)}
                {...register('lastName', {
                  required: formatMessage(messages.lastNameRequired),
                })}
              />
            </>
          )}
          {!isProvisionalChecked && (
            <>
              <TextField
                className="!mb-0"
                disabled={isSubmitting}
                id="libraryCard"
                error={errors?.libraryCard?.message}
                label={formatMessage(messages.libraryCard)}
                placeHolder={formatMessage(messages.libraryCard)}
                {...register('libraryCard', {
                  required: formatMessage(messages.libraryCardRequired),
                })}
              />

              {library?.pinRequired && (
                <TextField
                  disabled={isSubmitting}
                  id="libraryPin"
                  error={errors?.libraryPin?.message}
                  label={formatMessage(messages.libraryPin)}
                  maxLength={50}
                  placeHolder={formatMessage(messages.libraryPin)}
                  {...register('libraryPin', {
                    required: formatMessage(messages.libraryPinRequired),
                  })}
                />
              )}
            </>
          )}
        </div>

        {error && <p className="mb-6 text-sm text-red-600">{error}</p>}

        {library?.libraryCardUrl && (
          <a
            className="mx-auto mb-4 text-hoopla-blue hover:text-blue-400"
            href={library.libraryCardUrl}
            onClick={() => sendRegistrationEvent({ label: 'request_card' })}
            target="_blank"
            rel="noreferrer">
            <FormattedMessage
              id="registration.library.needCard"
              defaultMessage="Need a library card?"
            />
          </a>
        )}

        <div className="space-y-6">
          {library?.provisionalSignupAllowed && (
            <>
              <Checkbox
                {...register('provisional')}
                id="provisional"
                label={
                  <FormattedMessage
                    id="registration.library.provisional"
                    defaultMessage="I don't have a library card but I'd like to try hoopla"
                  />
                }
              />
            </>
          )}

          {library?.country !== 'US' && (
            <Checkbox
              {...register('nonUsAgreement', { required: 'nonUsAgreement' })}
              id="nonUsAgreement"
              error={errors?.nonUsAgreement?.message}
              label={
                <FormattedMessage
                  id="registration.library.info.ca.exchange"
                  defaultMessage="I agree to allow hoopla to verify and exchange my hoopla registration information with my public library. I acknowledge my registration information will be collected, accessed, and stored outside of {country}."
                  values={{
                    country: getCountryNameForLibrary(
                      library?.country as CountryCode,
                    ),
                  }}
                />
              }
            />
          )}
        </div>

        <PrimaryButton
          className="mx-auto mt-auto h-12 w-11/12"
          onClick={handleSubmit(onSubmit)}
          type="submit"
          disabled={isSubmitting}>
          {isSubmitting ? (
            <Loading secondary width={30} height={30} delay={0} />
          ) : (
            <FormattedMessage
              id="registration.button.next"
              defaultMessage="Next"
            />
          )}
        </PrimaryButton>
      </form>
    </div>
  )
}
