import {
  CreateLaunchDarklyContext,
  CreateLaunchDarklyUserContext,
} from 'util/launchDarkly'
import { useUser } from 'state/useUser'

import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useKidsMode } from 'state/useKidsMode'
import { useLibrary } from 'state/useLibrary'

const useLaunchDarklyObserver = () => {
  const locale = useIntl().locale
  const { isKidsMode } = useKidsMode()
  const ldClient = useLDClient()
  const library = useLibrary((state) => state.library)
  const fetchLibrary = useLibrary((state) => state.fetchLibrary)

  // Splitting apart the user context like this to avoid useEffect object hurdles
  // As well as navigating some typescript undefined property errors
  const { user } = useUser()

  useEffect(() => {
    if (!user) return

    if (!library) {
      fetchLibrary(user.patrons[0].libraryId)
    }

    const userContext = CreateLaunchDarklyUserContext(user, isKidsMode, library)
    const newContext = CreateLaunchDarklyContext(locale, userContext)

    if (ldClient) {
      ldClient.identify(newContext)
    } else {
      console.error('LDClient unavailable while updating user context.')
    }
  }, [isKidsMode, ldClient, locale, user, library, fetchLibrary])
}

export default useLaunchDarklyObserver
