// REACT IMPORTS
import { useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

// ABSOLUTE IMPORTS
import { api } from 'api/librariesApi'
import { CheckCircle } from 'components/common/icons'
import TextField from 'components/forms/TextField'
import useEvents from 'components/hooks/analytics/useEvents'
import debounce from 'lodash/debounce'

const messages = defineMessages({
  label: {
    id: 'library.finder.label',
    defaultMessage: 'Search by library name, city, state or zip',
  },
  placeholder: {
    id: 'library.finder.placeholder',
    defaultMessage: 'Search for your library',
  },
})

interface LibraryFinderProps {
  pageName?: string
  onChange: (library: Library | undefined) => void
  library?: Library
}

export default function LibraryFinder({
  pageName,
  onChange,
  library,
}: LibraryFinderProps) {
  // HOOKS
  const { sendRegistrationEvent, setPageLoaded } = useEvents()

  // STATE
  const [nearby, setNearby] = useState<Library[]>()
  const [autocompleteLibraries, setAutocompleteLibraries] =
    useState<Library[]>()
  const [selectedLibrary, setSelectedLibrary] = useState<Library | undefined>(
    library,
  )

  useEffect(() => {
    setPageLoaded(
      { name: pageName || 'REGISTRATION_FIND_LIBRARY' },
      {
        category: 'REGISTRATION',
        label: 'find_your_library',
      },
    )

    api.fetchNearbyLibraries().then((response) => {
      if (response?.data) {
        setNearby(response?.data)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { formatMessage } = useIntl()

  function textChanged(e: any) {
    getAutoCompleteLibraries(e?.target?.value)
  }

  const getAutoCompleteLibraries = debounce(async function (query) {
    if (query) {
      const results = await api.fetchAutocompleteLibraryResults(query)
      setAutocompleteLibraries(results?.data)
    } else {
      setAutocompleteLibraries(undefined)
    }
  }, 300)

  function onSelected(selected: Library) {
    const newLibrary =
      selected.id === selectedLibrary?.id ? undefined : selected
    setSelectedLibrary(newLibrary)
    onChange(newLibrary)

    if (newLibrary) {
      sendRegistrationEvent({
        label: 'library_selected',
        value: { libraryId: newLibrary.id.toString() },
      })
    }
  }

  function getDisplayLibraries() {
    if (autocompleteLibraries) {
      // check to see if we the user has typed a query
      if (autocompleteLibraries.length) {
        return checkSelectedInResults(selectedLibrary, autocompleteLibraries)
      } else {
        return autocompleteLibraries
      }
    } else if (nearby) {
      return checkSelectedInResults(selectedLibrary, nearby)
    } else {
      return []
    }
  }

  function getContent() {
    if (autocompleteLibraries?.length === 0) {
      return (
        <p className="text-lg font-semibold">
          <FormattedMessage
            id="library.find.no.results"
            defaultMessage="We couldn't find any libraries for that search term."
          />
        </p>
      )
    } else {
      return getDisplayLibraries()?.map((library: Library) => (
        <LibraryRow
          key={library.id}
          library={library}
          selected={library.id === selectedLibrary?.id}
          onSelected={onSelected}
        />
      ))
    }
  }
  return (
    <div className="mb-2 flex w-full flex-col items-center">
      <TextField
        id="query"
        name="query"
        onChange={textChanged}
        label={formatMessage(messages.label)}
        placeHolder={formatMessage(messages.placeholder)}
      />

      <div className="flex max-h-[300px] w-full flex-col overflow-y-auto">
        {getContent()}
      </div>
    </div>
  )
}

function LibraryRow({
  library,
  selected,
  onSelected,
}: {
  library: Library
  selected: boolean
  onSelected: (library: Library) => void
}) {
  return (
    <button
      onClick={() => onSelected(library)}
      type="button"
      className="flex w-full flex-row items-center justify-between bg-white px-3 py-2 odd:bg-gray-200 hover:bg-hoopla-blue/10 focus:z-10 focus:bg-hoopla-blue/10">
      <div className="flex-auto truncate text-left">
        <p className="truncate font-semibold">{library.name}</p>
        <p className=" truncate text-sm text-gray-700">
          <span className="capitalize">{library.city.toLowerCase()}</span>,{' '}
          <span className="uppercase">{library.state}</span>
        </p>
      </div>
      <div className="flex-0 w-[24px]">
        {selected && <CheckCircle className="fill-hoopla-blue" size={24} />}
      </div>
    </button>
  )
}

function checkSelectedInResults(
  selected: Library | undefined,
  results: Library[],
): Library[] {
  if (selected) {
    // if there is a selected library we need to check if it is in the current results
    const selectedInResults = results.some((l) => l.id === selected.id)
    if (selectedInResults) {
      // if the library is already there return it
      return results
    } else {
      // if the library is not there we need to add it in
      const showing = [...results]
      showing.unshift(selected)
      return showing
    }
  } else {
    return results
  }
}
