// REACT IMPORTS
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

// ABSOLUTE IMPORTS
import { Stepper } from 'components/registration/ui'
import HooplaLogo from 'images/hoopla_logo_no_r.png'
import { PrimaryButton } from 'ui/buttons/PrimaryButton'
import useEvents from 'components/hooks/analytics/useEvents'

export default function Introduction({
  onBack = () => {},
  onNext = () => {},
}: BaseRegistrationProps) {
  const location = useLocation()
  const prevLocation = location.state?.prevLocation
  const { sendRegistrationEvent, setPageLoaded } = useEvents()

  setPageLoaded(
    { name: 'REGISTRATION_WELCOME' },
    { category: 'REGISTRATION', label: 'registration_welcome' },
  )
  return (
    <div className="flex w-full flex-col items-center">
      <Stepper onBack={() => onBack()} />
      <img alt="hoopla" className="w-56" src={HooplaLogo} />

      <h1 className="mb-3 mt-5 text-2xl font-bold sm:text-3xl">
        <FormattedMessage
          id="registration.intro.welcome"
          defaultMessage="Welcome to hoopla!"
        />
      </h1>

      <div className="space-y-4 text-gray-700">
        <p>
          <FormattedMessage
            id="registration.intro.withHoopla"
            defaultMessage="With hoopla, your library card lets you instantly read, listen, and watch your favorite titles."
          />
        </p>
        <p>
          <FormattedMessage
            id="registration.intro.quickAndEasy"
            defaultMessage="Sign-up is quick and easy. Thanks to participating public libraries, access is FREE."
          />
        </p>

        <div>
          <p>
            <FormattedMessage
              id="registration.intro.allYouNeed"
              defaultMessage="All you need is:"
            />
          </p>

          <ul>
            <li className="left-1 list-inside list-disc before:-mr-1">
              <span>
                <FormattedMessage
                  id="registration.intro.validCard"
                  defaultMessage="Your valid library card number (and PIN if required)"
                />
              </span>
            </li>
            <li className="list-inside list-disc before:-mr-1">
              <span>
                <FormattedMessage
                  id="registration.intro.validEmail"
                  defaultMessage="A valid email address"
                />
              </span>
            </li>
          </ul>
        </div>
      </div>

      <PrimaryButton
        className="mt-6 mb-4 h-12 w-11/12"
        onClick={() => {
          sendRegistrationEvent({ label: 'lets_go' })
          onNext()
        }}>
        <FormattedMessage
          id="registration.intro.button"
          defaultMessage="Let's Go!"
        />
      </PrimaryButton>

      <div className="flex flex-col items-center space-y-1">
        <p className="text-center text-gray-500">
          <FormattedMessage
            id="registration.intro.conditions.privacy"
            defaultMessage="By registering, you agree to our terms and conditions and privacy policy"
          />
        </p>

        <Link
          onClick={() =>
            sendRegistrationEvent({ label: 'terms_and_conditions' })
          }
          to="/terms"
          target="_blank"
          rel="noopener">
          <FormattedMessage
            id="registration.intro.termsAndConditions"
            defaultMessage="Terms and Conditions"
          />
        </Link>
        <Link
          onClick={() => sendRegistrationEvent({ label: 'privacy_policy' })}
          to="/privacy"
          target="_blank"
          rel="noopener">
          <FormattedMessage
            id="registration.intro.privacyPolicy"
            defaultMessage="Privacy Policy"
          />
        </Link>
      </div>
      <p className="mt-4 mb-1 text-center">
        Already have an account?{' '}
        <Link
          onClick={() => sendRegistrationEvent({ label: 'login' })}
          to="/login"
          state={{ prevLocation }}
          replace>
          <FormattedMessage id="login.button" defaultMessage="Login" />
        </Link>
      </p>
    </div>
  )
}
